body {
  background: rgba(240, 235, 237, 0.89);
}

.card {
  background-color: rgba(255, 255, 255, 0.609);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 10px 20px 20px 2px rgba(8, 8, 8, 0.1);
  border-radius: 10px;
  margin: 2rem 0 2rem 0;
}

.comment-card {
  margin: -2.5rem 0 2rem 4rem;
}

textarea {
  border: none;
  background-color: rgba(255, 255, 255, 0.609) !important;
  border-radius: 10px;
  width: 100%;
  resize: none;
  overflow: hidden;
  height: fit-content;
}

.form-control:disabled,
.form-control[readonly] {
  border: none;
  border-radius: 10px;
  color: white;
}

.form-control {
  border: none;
  border-radius: 10px;
  box-shadow: 5px 2px 10px 2px rgba(8, 8, 8, 0.1);
}

.form-container {
  max-width: 500px;
  margin: 2rem auto;
  overflow: hidden;
  padding: 0 2rem;
}

.editor-class {
  background-color: rgba(255, 255, 255, 0.609);
  padding: 1rem;
  border: none;
}

.preview {
  padding: 1rem;
  margin-top: 1rem;
}

.navbar-dark .navbar-nav .nav-link {
  color: white;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(68, 60, 60, 0.781);
}

.bg-primary {
  background-color: #793adf !important;
}

#dropDown {
  margin-left: -5rem !important;
}

.dropdown-item {
  margin-left: 1rem !important;
}

.dropdown-item.nav-link:hover {
  width: 100% !important;
}

.nav-link.logout {
  margin-left: 1rem !important;
}

@media (min-width: 768px) {
  #question-form {
    z-index: 4;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
}

@media (max-width: 992px) {
  #dropDown {
    margin-left: 0rem !important;
  }
  .dropdown-item {
    margin-left: 1rem !important;
  }
  .nav-link.logout {
    margin-left: 1rem !important;
  }
}

@media (max-width: 500px) {
  .profile-pic {
    width: 75px;
    height: 75px;
    margin-top: -2.5rem !important;
  }

  .card {
    width: 90vw;
  }

  .homePost {
    justify-items: center;
    justify-content: center;
  }
}

.profile-pic {
  margin-top: -5rem;
  border: solid lightgray;
}

.nav-tabs {
  background-color: white;
  border-radius: 20px;
}

.nav-tabs .nav-link {
  color: black;
  font-size: 15px;
}

.nav-tabs .nav-link.active {
  background: linear-gradient(
    to left,
    rgba(106, 17, 203, 0.55),
    rgba(37, 117, 252, 0.55)
  );
  color: white;
  border-color: white;
  border-radius: 20px;
}

.nav-tabs .nav-link:hover {
  border-radius: 20px;
  background: linear-gradient(
    to left,
    rgba(106, 17, 203, 0.55),
    rgba(37, 117, 252, 0.55)
  );
  color: white;
}

.nav-pills .nav-link {
  background-color: whitesmoke;
  border-radius: 20px;
  color: black;
  font-size: 12px;
}

.nav-pills .nav-link.active {
  border-radius: 20px;
  background: linear-gradient(
    to left,
    rgba(106, 17, 203, 0.55),
    rgba(37, 117, 252, 0.55)
  );
}

.float-btn {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 30px;
  right: 30px;
  color: #fff;
  z-index: 4;
}

.read-more {
  display: inline;
  margin-left: 3px;
  color: #286da8;
  cursor: pointer;
  outline: 0;
  border: 0;
  background-color: transparent;
  font-size: 16px;
}

.read-more:hover {
  color: #4591d3;
}

.read-more:focus {
  outline: 0;
}

.dynamic-content-div img {
  display: block;
  max-width: 80%;
  border-radius: 15px;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
}

.page-link {
  -webkit-animation: none !important;
  transition: none;
}

.page-item .page-link {
  background-color: #e7dede;
  border-radius: 10px !important;
  margin: 0 1rem 0 1rem;
}

.page-item .page-link:focus {
  background-color: #e7dede;
  color: black;
  border-radius: 10px;
  margin: 0 1rem 0 1rem;
}

.page-item .page-link:hover {
  background-color: #999292;
  border-radius: 10px;
}

.pagination-ellipsis {
  margin: 1rem 1rem 0 0;
}

.page-item.active .page-link {
  background-color: #793adf;
  border-radius: 10px !important;
}
