*,
*:before,
*:after {
  box-sizing: border-box;
}

.modal-styles {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 40px;
  z-index: 1000;
  border-radius: 10px;

  max-height: 100%;
  overflow: auto;
}

.overlay-styles {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}
